<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="profilehub-criteria-list">
      <UiBanner>
        <template v-slot:content>
          <div class="tw-flex">
            <div>
              <UiTitle :title="$t('profilehub.criteria.list.title')" large />
              <UiSubtitle :subtitle="`${brandCriteria.length} ${$t('profilehub.criteria.list.subtitle')}`" large />
            </div>
            <v-spacer />
            <v-btn
              class="tw-ml-4"
              :elevation="2"
              color="primary"
              rounded
              :fab="$vuetify.breakpoint.xsOnly"
              :small="$vuetify.breakpoint.xsOnly"
              @click="openNew"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span v-if="!$vuetify.breakpoint.xsOnly">{{ $t('profilehub.criteria.list.actions.new') }}</span>
            </v-btn>
          </div>
        </template>
      </UiBanner>
      <UiContainer class="tw-relative tw-h-full" no-padding>
        <div class="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0">
          <v-data-table
            :headers="headers"
            :items="brandCriteria"
            fixed-header
            class="profilehub-criteria-list__table"
            height="100%"
            :loading="refreshing"
            :footer-props="{
              itemsPerPageOptions: [25, 50, 100],
            }"
            @click:row="getIndividualView"
          >
            <template #[`item.status`]="{ item }">
              <v-icon v-if="item.status === 1" color="#8ed380">{{ icons.mdiCheckCircle }}</v-icon>
              <v-icon v-else color="#EF9A9A">{{ icons.mdiCloseCircle }}</v-icon>
            </template>
            <template #[`item.required`]="{ item }">
              <v-icon v-if="item.required === 1" color="#8ed380">{{ icons.mdiCheckCircle }}</v-icon>
              <v-icon v-else color="#EF9A9A">{{ icons.mdiCloseCircle }}</v-icon>
            </template>
            <template #[`item.delete`]="{ item }">
              <v-btn icon @click="deleteCriteria(item)"
                ><v-icon color="#EF9A9A">{{ icons.mdiTrashCanOutline }}</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </div>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiPlus, mdiCheckCircle, mdiCloseCircle, mdiTrashCanOutline } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'ProfileHubCriteriaList',

  components: {
    UiBanner,
    UiTitle,
    UiSubtitle,
    UiContainer,
  },
  data: () => ({
    refreshing: false,
    icons: { mdiPlus, mdiCheckCircle, mdiCloseCircle, mdiTrashCanOutline },
  }),

  async created() {
    this.setUpdating(true)
    await this.getBrandCriteria()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      brandCriteria: state => state.profilehub.brandCriteria,
    }),
    headers() {
      return [
        { text: this.$t('profilehub.criteria.list.headers.label'), value: 'label' },
        { text: this.$t('profilehub.criteria.list.headers.slug'), value: 'slug' },
        { text: this.$t('profilehub.criteria.list.headers.status'), value: 'status' },
        { text: this.$t('profilehub.criteria.list.headers.required'), value: 'required' },
      ]
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getBrandCriteria: 'profilehub/getBrandCriteria',
    }),
    getIndividualView(item) {
      this.$router.push({ name: 'ProfileHubCriteriaIndividual', params: { id: item.id } })
    },
    openNew() {
      this.$router.push({ name: 'ProfileHubCriteriaIndividualCreate' })
    },
  },
}
</script>

<style lang="scss">
.profilehub-criteria-list {
  @apply tw-flex tw-h-full tw-flex-col;

  &__table {
    @apply tw-h-full tw-flex tw-flex-col;
  }

  &__table tbody tr:hover {
    cursor: pointer;
  }
}
</style>
